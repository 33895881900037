import React, { memo, useEffect, useMemo, useState } from 'react';
import { signOut, useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import classNames from 'classnames';
import { kennekLogoGreen } from 'kennek/assets';
import { DropDown } from 'ui';
import { obtainInitials } from 'ui/utils';
import {
  ChevronDownIcon,
  CogIcon,
  LogoutIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { ROUTES } from '@/constants/routes';
import { useBranchEncodedKey } from '@/hooks/useBranchEncodedKey';
import { useNotifications } from '@/hooks/useNotifications';
import { useGetBrandQueryState } from '@/services/kennek';
import { useSelectUser } from '@/store/user/selectors';
import { getImageDimensions } from '@/utils/helpers';
import EnvironmentBadge from './EnvironmentBadge';
import NotificationButton from './NotificationButton';

const BASE_LOGO_HEIGHT = 48;
const KENNEK_LOGO_GREEN_HEIGHT = 32;
const KENNEK_LOGO_GREEN_WIDTH = 127;

type HeaderProps = {
  sticky?: boolean;
  hideKennekData?: boolean;
};

const Header: React.FC<HeaderProps> = ({ sticky, hideKennekData = false }) => {
  const [logoDimensions, setLogoDimensions] = useState({
    width: KENNEK_LOGO_GREEN_WIDTH,
    height: KENNEK_LOGO_GREEN_HEIGHT,
  });

  const { data: session } = useSession();
  const router = useRouter();
  const user = useSelectUser();
  const { withNotifications } = useNotifications();

  const { branchEncodedKey } = useBranchEncodedKey();

  const brandQuery = useGetBrandQueryState({ branchEncodedKey });
  const brandLogoUrl = brandQuery?.currentData?.logo?.url;

  useEffect(() => {
    if (!brandLogoUrl) return;
    getImageDimensions(brandLogoUrl).then(({ width, height }) => {
      if (!width || !height) return;
      if (height > BASE_LOGO_HEIGHT) {
        const aspectRatio = width / height;
        const imageWidth = BASE_LOGO_HEIGHT * aspectRatio;
        setLogoDimensions({ width: imageWidth, height: BASE_LOGO_HEIGHT });
        return;
      }
      setLogoDimensions({ width, height });
    });
  }, [brandLogoUrl]);

  const dropDownItems = useMemo(
    () =>
      [
        {
          text: 'Settings',
          icon: <CogIcon />,
          onClickHandler: () => router.push(ROUTES.SETTINGS),
        },
        {
          text: 'Users',
          icon: <UserIcon />,
          onClickHandler: () => {
            router.push(ROUTES.USERS);
          },
          canShow: () =>
            !(
              session?.accessRole === 'BORROWER' &&
              (user?.borrowerExecutionIds?.length ?? 0)
            ),
          roles: ['ORIGINATOR_ADMIN', 'BORROWER', 'INVESTOR'],
        },
        {
          text: 'Log out',
          icon: <LogoutIcon />,
          onClickHandler: () => {
            signOut({ redirect: false });
          },
        },
      ].filter(
        (i) =>
          !i.roles ||
          i.roles.some((r) => {
            const canShow = i.canShow();
            return r === session?.accessRole && canShow;
          })
      ),
    [session, user]
  );

  if (hideKennekData) return null;

  return (
    <header
      className={classNames(
        'flex justify-between items-center px-9 shadow-md bg-white z-[100] h-[var(--nav-header-height)]',
        { 'sticky top-0 z-50': sticky }
      )}
    >
      <Link href={ROUTES.ROOT} className="shrink-0">
        <Image
          src={brandLogoUrl ?? kennekLogoGreen}
          width={logoDimensions.width}
          height={logoDimensions.height}
          alt="kennekLogo"
        />
      </Link>
      <EnvironmentBadge />

      <div className="flex justify-end gap-7 items-center w-full">
        {withNotifications && <NotificationButton />}

        <DropDown
          dropDownItems={dropDownItems}
          button={
            <>
              <ChevronDownIcon
                width={15}
                className="text-secondary-400 mr-[4px]"
              />
              <div className="bg-primary-200 text-primary-700 w-8 h-8 rounded-full leading-8">
                {session
                  ? obtainInitials(session?.user?.name)
                  : obtainInitials(user?.userName)}
              </div>
            </>
          }
        />
      </div>
    </header>
  );
};

export default memo(Header);
