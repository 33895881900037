import * as React from 'react';
import { useContext } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { formatAmount } from '@/utils/formatters';
import { LoanSummaryContext } from './ContextWrapper';

const ProcessingPaymentBanner: React.FC = () => {
  const {
    currency,
    productConfig,
    nextPaymentData: { processingPaymentAmounts },
  } = useContext(LoanSummaryContext);

  const processingAmount = processingPaymentAmounts?.processingPaymentAmount;
  const overpaymentAmount = processingPaymentAmounts?.overpaymentAmount;
  const isServiceFullInterestOn =
    productConfig?.payment?.serviceFullInterestPeriod;

  if (!processingAmount) return null;

  return (
    <div className="rounded-md bg-secondary-100 p-2 flex align-items-center mb-4 mt-1">
      <InformationCircleIcon height={20} width={20} className="mr-1" />
      <span className="text-neutral-800 heading-200 max-w-[265px]">
        {formatAmount(processingAmount, false, currency)} payment is being
        processed.
        {overpaymentAmount && isServiceFullInterestOn
          ? ` The overpayment of ${formatAmount(
              overpaymentAmount,
              false,
              currency
            )} will be processed on the next period.`
          : ''}
      </span>
    </div>
  );
};

export default ProcessingPaymentBanner;
