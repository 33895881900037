import { useRouter } from 'next/navigation';
import { ROUTES } from '@/constants/routes';
import {
  SNACKBAR_UNDERWRITING,
  SNACKBAR_USER_MGMT,
} from '@/constants/snackbar-messages';
import { useRole } from '@/hooks/useRole';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useCreateBorrowerUnderwritingMutation } from '@/services/accounts/borrower';
import {
  useExecutionAcceptMutation,
  useExecutionActionMutation,
  useGetExecutionToServicingRedirectionMutation,
  useTransferDataMutation,
} from '@/services/underwriting';
import { getRouterQuery } from '@/utils/helpers';
import { ToastData } from './types';
import { CreateUnderwritingBorrowerDto } from 'kennek/interfaces/accounts';
import { ExecutionServicingRedirectionDetails } from '@/interfaces/underwriting';

export const useGetExecution = ({
  setCompletePage,
  modulesRef,
  loanDetails,
  pagesQuery,
  executionQuery,
  selectNextPage,
}) => {
  const [executionAction] = useExecutionActionMutation();
  const [transferData, { isLoading: isLoadingTransferData }] =
    useTransferDataMutation();
  const [executionAccept] = useExecutionAcceptMutation();
  const [createBorrowerUnderwriting] = useCreateBorrowerUnderwritingMutation();

  const [getRedirectionParameters] =
    useGetExecutionToServicingRedirectionMutation();

  const { role: userRole } = useRole();

  const router = useRouter();

  const snackbar = useSnackbar();
  const executeAction = async ({
    pageId,
    action,
    executionId,
    buttonRef,
    createNoInviteBorrowerData,
  }: {
    pageId: string;
    action: string;
    executionId?: string;
    buttonRef?: string;
    createNoInviteBorrowerData?: CreateUnderwritingBorrowerDto;
  }): Promise<boolean> => {
    return new Promise((res, rej) => {
      switch (action) {
        case 'SAVE': {
          Promise.all(modulesRef?.current?.map((form) => form?.onComplete()))
            .then(() => {
              snackbar.show({
                severity: 'primary',
                title: SNACKBAR_UNDERWRITING.CHANGES_SAVE_SUCCESS,
                duration: 2000,
              });
              setCompletePage(pageId);
              res(true);
            })
            .catch(() => {
              rej();
            });
          break;
        }
        case 'BACK':
        case 'REFER':
        case 'CONTINUE':
          executionAction({
            pageId,
            executionId,
            actions: [action === 'CONTINUE' ? 'FORWARD' : action],
            buttonRef,
          })
            .unwrap()
            .then(async (actionedExecution) => {
              if (actionedExecution.toasts?.length) {
                actionedExecution.toasts.forEach(
                  ({ severity, title, content = '', duration }: ToastData) => {
                    snackbar.show({
                      severity,
                      title,
                      content,
                      duration,
                    });
                  }
                );
              }
              if (actionedExecution?.execution?.actorRole !== userRole) {
                if (userRole === 'BORROWER') {
                  router.push(
                    ROUTES.UNDERWRITING_REQUEST.replace(
                      ':executionId',
                      executionId as string
                    )
                  );
                } else if (userRole !== 'unauthenticated') {
                  router.push(ROUTES.UNDERWRITING_REQUESTS);
                } else {
                  router.refresh();
                }
              }

              pagesQuery.refetch();
              executionQuery.refetch();
              res(true);
            })
            .catch(() => {
              rej();
            });

          break;
        case 'REVERSE': {
          selectNextPage(pageId, [action]);
          rej();
          break;
        }
        case 'CREATE_BORROWER': {
          createBorrowerUnderwriting(createNoInviteBorrowerData)
            .unwrap()
            .then(() => res(true))
            .catch((e) => {
              snackbar.show({
                severity: 'error',
                title:
                  e?.data?.message?.message ||
                  SNACKBAR_USER_MGMT.CREATE_ALREADY_EXISTS,
              });
              rej();
            });
          break;
        }
        case 'MIGRATE': {
          loanDetails.migrationFailed = false;
          transferData({
            executionId,
            borrowerEmail: createNoInviteBorrowerData?.email,
          })
            .unwrap()
            .then(({ email }) => {
              loanDetails.borrowerEmail = email;
              res(true);
            })
            .catch((e) => {
              loanDetails.migrationFailed = true;
              snackbar.show({
                severity: 'error',
                title:
                  e?.data?.message ||
                  SNACKBAR_UNDERWRITING.LOAN_MIGRATION_FAILED,
              });
              rej();
            });
          break;
        }
        case 'ACCEPT': {
          if (!loanDetails?.migrationFailed) {
            executionAccept(executionId)
              .unwrap()
              .then(() => {
                snackbar.show({
                  severity: 'primary',
                  title: SNACKBAR_UNDERWRITING.LOAN_ACCEPTED_SUCCESS,
                });
                const query = loanDetails.loanId
                  ? { loanId: loanDetails.loanId, executionId }
                  : { borrowerEmail: loanDetails.borrowerEmail, executionId };
                const path = getRouterQuery(ROUTES.ONBOARDING_NEW, query);
                router.push(path);
                res(true);
              })
              .catch((e) => {
                snackbar.show({
                  severity: 'error',
                  title:
                    e?.data?.message ||
                    SNACKBAR_UNDERWRITING.LOAN_ACCEPTED_FAILED,
                });
                rej();
              });
          }

          break;
        }
        case 'SERVICING_REDIRECTION': {
          getRedirectionParameters({ executionId })
            .unwrap()
            .then((redirectionData) => {
              const { route, query } =
                getServicingRedirectionRouteParams(redirectionData);
              const path = getRouterQuery(route, query);

              router.push(path);
              res(true);
            })
            .catch((e) => {
              snackbar.show({
                severity: 'error',
                title:
                  e?.data?.message ||
                  SNACKBAR_UNDERWRITING.LOAN_REDIRECTION_FAILED,
              });
              rej();
            });
          break;
        }

        default:
          throw new Error(`action ${action} does not exist`);
      }
    });
  };

  return { executeAction, isLoadingTransferData };
};
function getServicingRedirectionRouteParams(
  redirectionData: ExecutionServicingRedirectionDetails
): {
  route: string;
  query: {
    loanId?: string;
    executionId?: string;
    borrowerEmail?: string;
  };
} {
  const commonQuery = redirectionData.loanId
    ? {
        loanId: redirectionData.loanId,
        executionId: redirectionData.executionId,
      }
    : {
        borrowerEmail: redirectionData.borrowerEmail,
        executionId: redirectionData.executionId,
      };

  if (redirectionData.redirectionType === 'DETAILS') {
    return { route: ROUTES.LOAN_DETAILS, query: commonQuery };
  } else {
    return { route: ROUTES.ONBOARDING_NEW, query: commonQuery };
  }
}
