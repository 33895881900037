import { orderBy } from 'lodash';
import { NIL } from 'uuid';
import { PAGE_WAITING_ID } from '@/components/uaas/types';
import {
  useGetExecutionQuery,
  useGetFlowModulesQuery,
  useGetFlowPagesQuery,
  useGetFlowQuery,
  useGetModulesQuery,
  useGetPagesQuery,
} from '@/services/underwriting';

interface Params {
  executionId: string;
  flowId: string;
  selectedPageId: string;
  loadOnlyWaitingPage: boolean;
}

export const useGetUaasData = ({
  executionId,
  flowId,
  selectedPageId,
  loadOnlyWaitingPage,
}: Params) => {
  const executionQuery = useGetExecutionQuery(
    { id: executionId },
    { skip: !executionId, refetchOnMountOrArgChange: true }
  );

  const flowQuery = useGetFlowQuery(
    { flowId },
    { skip: !flowId || !!executionId }
  );

  const pagesQuery = useGetPagesQuery(
    { executionId },
    {
      skip: !executionId,
      refetchOnMountOrArgChange: true,
    }
  );

  const flowPagesQuery = useGetFlowPagesQuery(
    { flowId },
    {
      skip: !flowId || !!executionId,
    }
  );
  const pages = pagesQuery?.currentData ?? flowPagesQuery?.currentData ?? [];

  const orderedPages = orderBy(pages, ['order'], ['asc']);
  const lastPage = orderedPages?.at(-1);
  const firstPage = orderedPages?.at(0);
  const currentPageByOrder = flowId ? firstPage?.id : lastPage?.id;

  const currentPageId = selectedPageId
    ? selectedPageId
    : (executionQuery?.currentData?.currentPageId ?? currentPageByOrder);

  const modulesQuery = useGetModulesQuery(
    {
      executionId,
      pageId: loadOnlyWaitingPage ? PAGE_WAITING_ID : currentPageId,
    },
    {
      skip: !executionId || !currentPageId,
    }
  );

  const flowModulesQuery = useGetFlowModulesQuery(
    { flowId, pageId: currentPageId },
    {
      skip: !flowId || !currentPageId || !!executionId,
    }
  );

  const isDashboard = currentPageId === NIL;

  const isExecutionFetched =
    executionQuery?.isFetching && !executionQuery?.currentData;
  const isPagesFetched = pagesQuery?.isFetching && !pagesQuery?.currentData;
  const isFlowQueryFetched = flowQuery?.isFetching && !flowQuery?.currentData;
  const isFlowPagesQueryFetched =
    flowPagesQuery?.isFetching && !flowPagesQuery?.currentData;

  const isLoadingData =
    isExecutionFetched ||
    isPagesFetched ||
    isFlowQueryFetched ||
    isFlowPagesQueryFetched ||
    (!executionId && !flowId);

  const isLoadingModule =
    executionQuery?.isFetching ||
    modulesQuery?.isFetching ||
    flowQuery?.isFetching ||
    flowModulesQuery?.isFetching;

  return {
    executionQuery,
    flowQuery,
    pagesQuery,
    flowPagesQuery,
    modulesQuery,
    flowModulesQuery,
    currentPageId,
    lastPage,
    pages,
    isLoadingData,
    isLoadingModule,
    isDashboard,
  };
};
