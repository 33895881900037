import * as React from 'react';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Button } from 'ui';
import { ROUTES } from '@/constants/routes';
import useGetUserIdentity from '@/hooks/useGetUserIdentity';
import { getRouterQuery } from '@/utils/helpers';
import { LoanSummaryContext } from './ContextWrapper';
import { DirectDebitMandateStatus } from '@/interfaces/loans/queries';
import { PaymentButtonRedirectData } from '@/interfaces/next-payment';

const supportedDirectDebitMandateStatus: DirectDebitMandateStatus[] = [
  'UNDER_REVIEW',
  'ACTIVE',
  'INACTIVE',
];

type Props = {
  setShowPaymentModal: (isActive: boolean) => void;
  loanId?: string;
};

const PaymentButton: React.FC<Props> = ({ setShowPaymentModal, loanId }) => {
  const router = useRouter();
  const { isBorrower } = useGetUserIdentity();
  const { nextPaymentData, directDebitMandateStatus } =
    useContext(LoanSummaryContext);

  const getRedirectionData = (
    isBorrower: boolean
  ): PaymentButtonRedirectData =>
    isBorrower
      ? {
          text: 'View bank details',
          type: 'view-bank-details',
          callback: () => setShowPaymentModal(true),
        }
      : {
          text: 'Reconcile payment',
          type: 'reconcile-payment',
          callback: () =>
            router.push(getRouterQuery(ROUTES.LOAN_PAYMENT, { loanId })),
        };

  const { text, type, callback } = getRedirectionData(isBorrower);

  if (
    !supportedDirectDebitMandateStatus.some(
      (status) => status === directDebitMandateStatus
    ) ||
    (type === 'view-bank-details' && !nextPaymentData.beneficiaryIdentifier)
  ) {
    return null;
  }

  const isPaymentButtonDisabled =
    (type === 'reconcile-payment' && !loanId) ||
    (isBorrower && !nextPaymentData?.borrowerPaymentEnabled);

  return (
    <div className="my-4">
      <Button
        className="w-[180px] transition-transform duration-300"
        layout={directDebitMandateStatus === 'ACTIVE' ? 'outline' : 'primary'}
        onClick={callback}
        disabled={isPaymentButtonDisabled}
      >
        {text}
      </Button>
    </div>
  );
};

export default PaymentButton;
