import { ApplyInterestStateStatusEnum } from '@/enums/apply-interest-state';
import { NextPaymentData } from '../next-payment';
import type {
  AmountToFundType,
  Asset,
  Balance,
  FeeRule,
  InterestGraceHandleMethod,
  InvestmentAmountType,
  Loan,
  LoanState,
  LoanSubState,
  NET_LOAN_CALCULATION_METHOD_OPTIONS,
  OnboardingTasks,
  Tranche,
} from './index';
import { InstallmentStates } from './installment';
import {
  InvestmentType,
  InvestorRow,
  LenderOfRecord,
  OriginatorInvestor,
} from 'kennek/interfaces/accounts/investor';
import { GetConfigurationProductResponse } from 'kennek/interfaces/kennek/queries';
import { Currencies } from 'kennek/interfaces/loans';
import { AvailabilitySettings } from 'kennek/interfaces/products';

export interface GetLoans {
  data: GetLoanData[];
  limit: number;
  offset: number;
  total: number;
}

export interface OnboardingLoanData {
  id: string;
  loanName: string;
  encodedKey: string;
  onboardingTasks: OnboardingTasks[];
  accountHolderKey: string;
  companyName: string;
  companyNumber: string;
  createdAt: string;
  disbursementDate: string;
  loanAmount: number;
  loanState: LoanState;
  currency?: Currencies;
  numberOfInstallments: number;
  initialLoan: boolean;
  userActivated?: boolean;
  startDate?: string;
  rate?: number;
  type?: string;
  loanProductEncodedKey: string;
  graceAmount: number;
  assignedBranchKey?: string;
  borrowerEmail?: string;
}

export interface GetLoanData extends OnboardingLoanData {
  groupId: string;
  loanProductEncodedKey: string;
  rate: number;
  displayedInterestRate: number;
  startDate: string;
  type: string;
  netLoanCalculationMethod?: NET_LOAN_CALCULATION_METHOD_OPTIONS;
  ltc?: number;
  gdc?: number;
  ltv?: number;
  gdv?: number;
  interestGraceHandleMethod?: InterestGraceHandleMethod;
  productTypeKey?: string;
}

export interface RepaymentsQuery {
  loanId?: string;
  fromDate?: string;
  dateTo?: string;
  consolidate?: boolean;
}

export interface LoansQuery {
  originatorEmail?: string;
  borrowerEmail?: string;
  loanStatus?: string;
  offset?: number;
  limit?: number;
  orderBy?: 'CREATION_DATE' | 'AMOUNT' | 'NUMBER_OF_INSTALLMENTS';
  orderCriteria?: 'ASC' | 'DESC';
  companyName?: string;
  loanProduct?: string;
}

export interface ApprovedLoansQuery {
  page?: number;
  pageSize?: number;
  productEncodedKey?: string;
  companyName?: string;
  loanStatus?: ApprovedLoanStateSearch;
  borrowerEmail?: string;
  originatorEmail?: string;
  investorEmail?: string;
  originatorExternalId?: string;
  sortBy?: ApprovedLoanSearchSortBy;
  sortOrder?: ApprovedLoanSearchSortOrder;
}

export enum ApprovedLoanStateSearch {
  APPROVED = 'APPROVED',
  REPAID = 'REPAID',
  SET_FOR_DISBURSEMENT = 'SET_FOR_DISBURSEMENT',
}

export enum ApprovedLoanSearchSortBy {
  DISBURSEMENT_DATE = 'DISBURSEMENT_DATE',
}

export enum ApprovedLoanSearchSortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type DirectDebitMandateStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'UNDER_REVIEW'
  | 'SUSPENDED'
  | 'REJECTED'
  | 'CANCELLED';

export enum DirectDebitMandateStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  UNDER_REVIEW = 'UNDER_REVIEW',
  SUSPENDED = 'SUSPENDED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

interface LoanBalanceSummary {
  balance: number;
  balancePaidPercent: number;
  fees: number;
  feesPaid: number;
  interest: number;
  interestPaid: number;
  principal: number;
  sumOfPaid: number;
  totalOutstanding: number;
}

export enum PendingPayoffState {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Applied = 'APPLIED',
}

export interface ProcessingPaymentAmounts {
  overpaymentAmount: number;
  processingPaymentAmount: number;
}

export interface LoanSummary {
  acceptPrepayments: boolean;
  assetInformation: Asset[];
  /**
   * Mambu calculated loan balances
   */
  balances: Balance;
  id?: string;
  borrowerCompany: string;
  borrowerCompanyNumber: string;
  borrowerCompanyAddress: BorrowerAddress;
  directDebitMandateStatus?: DirectDebitMandateStatus;
  disbursementDate: string;
  previewDisbursementDate?: string;
  feesSummary: LoanFeeSummary[];
  interestRate: number;
  displayedInterestRate: number;
  defaultInterestRate?: number;
  loanName: string;
  nextInstallmentDueDate: string;
  nextInstallmentEncodedKey: string;
  nextInstallmentPaymentDue: number;
  nextInstallmentState: InstallmentStates;
  processingPaymentAmounts: ProcessingPaymentAmounts;
  maturityDate: string;
  monthlyRepaymentDay?: number;
  originatorName: string;
  remainingInstallmentsCount: number;
  principalExpected?: number;
  repaymentInstallments: number;
  state?: LoanState;
  totalAmount: number;
  totalBalance: number;
  totalDueAmount: number;
  totalOverdueAmount: number;
  totalGraceAmount: number;
  totalPaidAmount: number;
  tranches?: Tranche[];
  interestGraceInstallments?: number;
  type?: string;
  productConfiguration: Pick<
    GetConfigurationProductResponse,
    'otherConfigurations' | 'payment' | 'grace'
  >;
  paymentHoliday?: number;
  payoffState?: PendingPayoffState;
  principalCollectionFrequency?: number;
  currency?: Currencies;
  investedAmount?: number;
  investorInterest?: number;
  netLoanCalculationMethod?: NET_LOAN_CALCULATION_METHOD_OPTIONS;
  interestGraceHandleMethod?: InterestGraceHandleMethod;
  ltc?: number;
  gdc?: number;
  ltv?: number;
  gdv?: number;
  interestGraceDeducted?: number;
  exitFeeAmount?: number;
  interestExpected?: number;
  totalRepaymentAmount?: number;
  leftToPay?: number;
  nextPayment: NextPaymentData;
  /**
   * @deprecated use balanceSummary instead
   */
  accruingLoanWithDecliningBalanceBalances?: LoanBalanceSummary;
  /**
   * Platform calculated loan balance
   */
  balanceSummary?: LoanBalanceSummary;
  applyInterestState: ApplyInterestState;
  productTypeKey: string;
  availabilitySettings?: AvailabilitySettings;
  isSetForDisbursment: boolean;
  baseIndexRate?: string;
  /**
   * Sum of disbursements amounts from all related loans (restructured and current one)
   */
  consolidatedDisbursements: number;
  firstHistoricalDisbursementDate: string;
  locks?: LoanLocks;
  rollUpDuration?: number;
  executionId?: string;
  firstRepaymentDate?: string;
}

export interface ApplyInterestState {
  status: ApplyInterestStateStatusEnum;
  processingInstallmentNumber: number;
  totalInstallmentsCount: number;
}

export interface LoanGroup {
  details: LoanSummary[];
  currency: Currencies;
  totalAmount: number;
  totalPaid: number;
  principalPaid: number;
  principalAmount: number;
  feesPaid: number;
  feesAmount: number;
  interestPaid: number;
  interestAmount: number;
}

/**
 * Group of loans, as returned by the API.
 */
export interface Group {
  loanIds: Array<string>;
  id: string;
  name: string;
}

export interface LoanFeeSummary {
  amount: number;
  name: string;
  trigger: FeeRule['trigger'];
}

export interface LoanTrancheSummary {
  totalDisbursed: number;
  pendingDisbursement: number;
  maxDisbursement: number;
  tranchesDisbursed: number;
  tranchesRemaining: number;
  currency?: Currencies;
}

export interface LoanLocks {
  edit: boolean;
  payments: boolean;
  interestApply: boolean;
  feesApply: boolean;
  penaltiesApply: boolean;
  disbursemet: boolean;
}

export interface DirectDebitMutationPayload {
  address: {
    line1: string;
    line2?: string;
    postTown: string;
    postCode?: string;
    countryCode?: string;
  };
  loanId: string;
  bankAccountName: string;
  bankAccountNumber: string;
  email: string;
  phone: string;
  sortCode: string;
}

export type DirectDebitCompleteAnonymousSetupPayload = Omit<
  DirectDebitMutationPayload,
  'email'
>;

export interface ScheduleDirectDebitPayload {
  loanEncodedKey: string;
  installmentEncodedKey?: string;
  scheduleDate?: string;
  amount: number;
  scheduleSource: ModulrCollectionScheduleSource;
}

export enum ModulrCollectionScheduleType {
  INSTALLMENT = 'INSTALLMENT',
  DAY = 'DAY',
}

export enum ModulrCollectionScheduleSource {
  MANUAL = 'MANUAL',
  OVERDUE = 'OVERDUE',
}

export interface DirectDebitStatus {
  manualDirectDebitEnabled: boolean;
  lastDirectDebitFailReason?: string;
  nextValidCollectionDate?: string;
  nextValidCollectionInstallmentDueDate?: string;
  nextValidCollectionInstallmentEncodedKey?: string;
  nextValidCollectionAmount?: number;
  nextValidCollectionOverdueAmount?: number;
  scheduleTypes?: ModulrCollectionScheduleType[];
  scheduleSource?: ModulrCollectionScheduleSource;
}

export interface BorrowerAddress {
  city?: string;
  country?: string;
  encodedKey?: string;
  indexInList?: 0;
  region?: string;
  line1?: string;
  line2?: string;
  parentKey?: string;
  postcode?: string;
}

export type LoanInvestment = {
  currentAmount: number;
  currentLoanEncodedKey?: Loan['encodedKey'];
  currentPercentage?: number;
  currentInterest?: number;
  default?: boolean;
  investor: OriginatorInvestor;
  lenderOfRecord: LenderOfRecord;
  _id?: string;
  currency?: Currencies;
  type?: InvestmentType;
};

export interface Investment {
  investorId: string;
  investmentId: string;
  partialInterestDue: number;
  funding: number;
  default: boolean;
}

export interface CashFlowRow {
  installmentNumber: string;
  interestDue: number;
  dueDate: Date;
  investments: Investment[];
}

export interface LoanInvestorsMutationPayload {
  loanEncodedKey: Loan['encodedKey'];
  investments: InvestmentPayload[];
  originatorEncodedKey?: OriginatorInvestor['originatorEncodedKey'];
  amountToFundType?: AmountToFundType;
  effectiveDate?: string;
  fundingMethodId?: string;
  investmentAmountType: InvestmentAmountType;
}

export type InvestmentPayload = Pick<
  InvestorRow,
  | 'investorId'
  | 'lenderOfRecordId'
  | 'amount'
  | 'investorInterest'
  | 'investmentType'
  | 'percentage'
> & { id?: string };

export interface DirectDebitCollection {
  installmentEncodedKey: string;
  amount: number;
  date: string;
  status:
    | 'COLLECTION_SCHEDULED'
    | 'FAILED_TO_SCHEDULE_COLLECTION'
    | 'FAILED_TO_SCHEDULE_COLLECTION_PROXIMITY_DUE_DATE'
    | 'FAILED_TO_COLLECT'
    | 'COLLECTED';
}

export type editType =
  | 'LOAN_REPORTING'
  | 'DOCUMENTS'
  | 'INVESTMENT'
  | 'BORROWER_COMPANY'
  | 'USER';

export type editAction = 'POST' | 'DELETE' | 'PATCH';

export interface EditLoanPayload {
  type: editType;
  action: editAction;
  requestData?: any;
  file?: any;
}

export interface ApprovedLoan {
  id: string;
  encodedKey: string;
  name: string;
  loanAmount: number;
  loanState: LoanState;
  loanSubState?: LoanSubState;
  type: string;
  companyName: string;
  companyNumber: string;
  disbursementDate: string;
  firstRepaymentDate?: string;
  createdAt: string;
  productEncodedKey: string;
  borrowerCompanyExternalId: string;
  originatorCompanyExternalId: string;
  borrowerEmail?: string;
  userActivated?: boolean;
  userVerified?: boolean;
  groupId?: string;
  productTypeKey?: string;
}

export interface PaginatedResponse<T> {
  data: T;
  offset: number;
  limit: number;
  total: number;
}

export interface LoanUserSettingsQuery {
  loanEncodedKey: string;
  userId: string;
}

export interface LoanUserSettingsPayload {
  loanEncodedKey: string;
  userId: string;
  repaymentScheduleUserSettings: RepaymentScheduleUserSettingsPayload;
}

export interface RepaymentScheduleUserSettingsPayload {
  predictedCashflow?: boolean;
}

export enum InvestmentFundingMethodFeeSettingsAdjustmentType {
  CAPITALIZE = 'CAPITALIZE',
  DEDUCT = 'DEDUCT',
}

export interface InvestmentFundingMethod {
  id: string;
  name: string;
  fees: InvestmentFundingMethodFeeSettings[];
  isPredefined: boolean;
  amountToFundType: AmountToFundType;
}

export interface InvestmentFundingMethodFeeSettings {
  feeId: string;
  name: string;
  enabled: boolean;
  adjustmentType: InvestmentFundingMethodFeeSettingsAdjustmentType;
}

export interface UpsertInvestmentFundingMethodDto {
  name: string;
  fees: Array<{ feeId: string; enabled: boolean }>;
}
