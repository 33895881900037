import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormInput, Title } from 'ui';
import * as yup from 'yup';
import { UserIcon } from '@heroicons/react/outline';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingAnimationPlat from '@/components/common/LoadingAnimationPlat';
import { USER_INFORMATION_TEXTS, USER_MESSAGES } from '@/constants/onboarding';
import { SNACKBAR_ONBOARDING } from '@/constants/snackbar-messages';
import { BorrowerType } from '@/enums/borrower-type.enum';
import useDebouncedIsLoading from '@/hooks/useDebouncedIsLoading';
import useGetLabelsConfig from '@/hooks/useGetLabelsConfig';
import { useSnackbar } from '@/hooks/useSnackbar';
import { useGetUserByEmailQuery } from '@/services/accounts';
import {
  useCreateBorrowerMutation,
  useGetOnboardingTaskStatusesQuery,
  usePatchUserMutation,
} from '@/services/accounts/borrower';
import { useCreateBorrowerCompanyMutation } from '@/services/originator/borrower-company';
import { isFetchBaseQueryError } from '@/services/utils';
import { setSnackBar } from '@/store/alerts';
import { useAppDispatch } from '@/store/hooks';
import { useSelectUser } from '@/store/user/selectors';
import {
  onBorrowerClientCreated,
  onBorrowerCreated,
  onBorrowerUpdated,
  onEnterBorrower,
  onExitBorrower,
  useSelectBorrowerForm,
  useSelectCompanyForm,
} from './onboardingSlice';
import { User } from 'kennek/interfaces/accounts';
import {
  BorrowerCompany,
  BorrowerCompanyPayload,
} from '@/interfaces/originator/borrower-company';

interface UserInformationFormProps {
  onContinue: () => void;
  onBack: () => void;
  onCompanyValidationError: () => void;
  onChangeBorrowerEmail: (borrowerEmail: string) => void;
  isEdit: boolean;
  isReview: boolean;
  initialFormValues: UserForm;
}

const schema = yup
  .object({
    firstName: yup.string().required(USER_MESSAGES.REQUIRED_FIRST_NAME),
    lastName: yup.string().required(USER_MESSAGES.REQUIRED_LAST_NAME),
    email: yup.string().email().required(USER_MESSAGES.REQUIRED_EMAIL),
  })
  .required();

export type UserForm = {
  email: string;
  firstName: string;
  lastName: string;
};

type ContainerProps = Pick<
  UserInformationFormProps,
  | 'onContinue'
  | 'onBack'
  | 'onChangeBorrowerEmail'
  | 'isReview'
  | 'onCompanyValidationError'
> & {
  borrowerEmail: string;
  loanId?: string;
};

const Container = ({ borrowerEmail, loanId, ...props }: ContainerProps) => {
  const user = useSelectUser();

  const { data: borrower, ...borrowerQuery } = useGetUserByEmailQuery(
    {
      email: borrowerEmail,
      mambuBranchEncodedKey: user?.mambuUser?.[0]?.mambuBranchEncodedKey,
    },
    { skip: !borrowerEmail || !user?.mambuUser?.[0]?.mambuBranchEncodedKey }
  );

  const { data: onboardingTasks = [], ...onboardingTasksQuery } =
    useGetOnboardingTaskStatusesQuery(
      {
        loanId: loanId ?? undefined,
        borrowerEmail,
      },
      { skip: !borrowerEmail }
    );

  const isEdit =
    onboardingTasks?.find(({ name }) => name === 'USER')?.status ===
    'COMPLETED';

  const borrowerForm = useSelectBorrowerForm();

  const initialFormValues = borrowerForm
    ? borrowerForm
    : borrower
      ? mapBorrowerToForm(borrower)
      : undefined;

  if (borrowerQuery.isFetching || onboardingTasksQuery.isFetching)
    return (
      <div className="flex justify-center items-center">
        <LoadingAnimationPlat fitBox={!props.isReview} />
      </div>
    );

  return (
    <UserInformationForm
      isEdit={isEdit}
      initialFormValues={initialFormValues}
      {...props}
    />
  );
};

const UserInformationForm: React.FC<UserInformationFormProps> = ({
  onContinue,
  onBack,
  onCompanyValidationError,
  onChangeBorrowerEmail,
  isEdit,
  isReview,
  initialFormValues,
}) => {
  const dispatch = useAppDispatch();
  const labelsConfig = useGetLabelsConfig();

  const companyForm = useSelectCompanyForm();
  const snackbar = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    control,
  } = useForm<UserForm>({
    shouldFocusError: false,
    defaultValues: initialFormValues,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const isDirtyRef = useRef(isDirty);
  const userInformationText = useMemo(() => {
    return USER_INFORMATION_TEXTS(labelsConfig);
  }, [labelsConfig]);

  useLayoutEffect(() => {
    isDirtyRef.current = isDirty;
  }, [isDirty]);

  useEffect(() => {
    dispatch(onEnterBorrower());

    return () => {
      dispatch(
        onExitBorrower({ form: getValues(), isDirty: isDirtyRef.current })
      );
    };
  }, [dispatch, getValues]);

  const [createBorrower, { isLoading: isCreatingBorrower }] =
    useCreateBorrowerMutation();
  const [
    createBorrowerCompanyMutation,
    { isLoading: isCreatingBorrowerCompany },
  ] = useCreateBorrowerCompanyMutation();

  const [patchUser, { isLoading: isPatchingUser }] = usePatchUserMutation();

  const isSubmitting = useDebouncedIsLoading(
    [isCreatingBorrower || isPatchingUser || isCreatingBorrowerCompany],
    800
  );

  const [borrowerCompany, setBorrowerCompany] =
    React.useState<BorrowerCompany>();
  const createOrReturnBorrowerCompany = (): Promise<BorrowerCompany> => {
    if (borrowerCompany) {
      return Promise.resolve(borrowerCompany);
    }

    const borrowerCompanyPayload: BorrowerCompanyPayload = {
      number: companyForm?.number,
      country: companyForm?.country,
      name: companyForm?.name,
      sicCode: companyForm?.sicCode,
      region: companyForm?.region,
      companyDateOfCreation:
        companyForm?.borrowerType === BorrowerType.COMPANY
          ? companyForm?.companyDateOfCreation
          : new Date().toISOString().split('T')[0],
      borrowerType: companyForm?.borrowerType,
      addressLine1: companyForm?.addressLine1,
      addressLine2: companyForm?.addressLine2,
      city: companyForm?.city,
      postalCode: companyForm?.postalCode,
    };

    return createBorrowerCompanyMutation(borrowerCompanyPayload)
      .unwrap()
      .then((createdCompany) => {
        setBorrowerCompany(createdCompany);
        return createdCompany;
      })
      .catch((error) => {
        if (isFetchBaseQueryError(error) && error.status === 400) {
          snackbar.show({
            severity: 'error',
            title:
              SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_TITLE(labelsConfig),
            content:
              SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_INVALID_COMPANY,
          });
          onCompanyValidationError();
        } else if (isFetchBaseQueryError(error) && error.status === 409) {
          snackbar.show({
            severity: 'error',
            title:
              SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_DUPLICATE_COMPANY_TITLE(
                labelsConfig
              ),
            content:
              SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_DUPLICATE_COMPANY_MESSAGE(
                labelsConfig
              ),
          });
        } else {
          snackbar.show({
            severity: 'error',
            title:
              SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_TITLE(labelsConfig),
            content:
              SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_CONTENT_INTERNAL_ERROR,
          });
        }

        return undefined;
      });
  };

  const onSubmit = handleSubmit((data: UserForm) => {
    if (!isEdit) {
      return createOrReturnBorrowerCompany().then((borrowerCompany) => {
        return createBorrower({
          companyId: borrowerCompany?._id,
          action: 'ONBOARDING',
          ...data,
        })
          .unwrap()
          .then((user) => {
            dispatch(onBorrowerCreated());
            dispatch(
              onBorrowerClientCreated({
                borrowerCompanyExternalId:
                  user.mambuUser.at(0).mambuClientEncodedKey,
                originatorExternalId:
                  user.mambuUser.at(0).mambuBranchEncodedKey,
              })
            );
            onChangeBorrowerEmail(user.email);
            snackbar.show({
              severity: 'primary',
              title: SNACKBAR_ONBOARDING.BORROWER_CREATION_TITLE(labelsConfig),
              content:
                SNACKBAR_ONBOARDING.BORROWER_CREATION_CONTENT(labelsConfig),
            });
            onContinue();
          })
          .catch((error) => {
            if (isFetchBaseQueryError(error) && error.status === 400) {
              snackbar.show({
                severity: 'error',
                title:
                  SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_TITLE(
                    labelsConfig
                  ),
                content:
                  SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_CONTENT_BAD_REQUEST,
              });
            } else if (isFetchBaseQueryError(error) && error.status === 409) {
              snackbar.show({
                severity: 'error',
                title:
                  SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_CONTENT_DUPLICATED_USER_TITLE,
              });
            } else {
              snackbar.show({
                severity: 'error',
                title:
                  SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_TITLE(
                    labelsConfig
                  ),
                content:
                  SNACKBAR_ONBOARDING.BORROWER_CREATION_FAILED_CONTENT_INTERNAL_ERROR,
              });
            }
          });
      });
    } else {
      patchUser({
        userName: `${data.firstName} ${data.lastName}`,
        email: data.email,
      })
        .unwrap()
        .then((user) => {
          dispatch(onBorrowerUpdated());
          onChangeBorrowerEmail(user.email);
          dispatch(
            setSnackBar({
              severity: 'primary',
              title: SNACKBAR_ONBOARDING.USER_UPDATED,
            })
          );
          onContinue();
        })
        .catch((error) => {
          if (isFetchBaseQueryError(error) && error.status === 404) {
            snackbar.show({
              severity: 'error',
              title: SNACKBAR_ONBOARDING.USER_UPDATE_FAILED_NOT_FOUND,
            });
          } else {
            snackbar.show({
              severity: 'error',
              title: SNACKBAR_ONBOARDING.USER_UPDATE_FAILED,
            });
          }
        });
    }
  });

  return (
    <div className="xl:w-6/12">
      <Title
        title={userInformationText.TITLE}
        icon={<UserIcon />}
        titleSize="lg"
        className="mt-2 stepper-form"
        subtitle={userInformationText.SUBTITLE}
      />
      <form
        onSubmit={(e) => e.preventDefault()}
        className="stepper-form"
        autoComplete="off"
        noValidate
      >
        <div className="flex items-top justify-between">
          <FormInput
            label="First name"
            placeholder="Name"
            errors={errors?.firstName?.message}
            {...register('firstName', {})}
            className="w-[190px]"
          />

          <FormInput
            label="Last name"
            placeholder="Last name"
            errors={errors?.lastName?.message}
            {...register('lastName', {})}
            className="w-[190px]"
          />
        </div>

        <FormInput
          label="Email"
          placeholder="User email"
          disabled={isEdit}
          errors={errors?.email?.message}
          {...register('email')}
        />

        <div className="flex justify-between mt-6">
          <Button onClick={onBack} layout="ghost" disabled={isSubmitting}>
            {isReview ? 'Cancel' : 'Back'}
          </Button>

          <Button
            onClick={onSubmit}
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {isReview ? 'Save' : 'Save and continue'}
          </Button>
        </div>
      </form>

      <DevTool control={control} />
    </div>
  );
};

export const mapBorrowerToForm = (borrower: User): UserForm => ({
  email: borrower?.email,
  firstName: borrower?.userName?.split(' ')[0],
  lastName: borrower?.userName?.split(' ')[1],
});

export default Container;
